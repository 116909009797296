<template>
  <v-container fluid>
    <v-card class="pa-2 mb-2">
      <v-row dense>
        <v-col cols="12">

          <ButtonWithTooltip
            icon="mdi-key"
            text="Show Xero Auth URL"
            tooltip="Get Xero Auth URL"

            @click="getXeroAuthUrl"
          /><br /><br />
          <hr /><br />
          <h2>Send PortaOne invoices to Xero</h2>

          <v-form ref="form" v-model="isValid">
            <v-row>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  placeholder="YYYY-MM-DD"
                  label="Issued After"
                  name="issued_after"
                  v-model="form.issued_after"
                  type="text"
                  :rules="rules.date"
                  required
                />
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  placeholder="YYYY-MM-DD"
                  label="Issued Before"
                  name="issued_before"
                  v-model="form.issued_before"
                  type="text"
                  :rules="rules.date"
                  required
                />
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-btn @click="submitPreview" :disabled="isBusy || !isValid" block large>Preview</v-btn>
              </v-col>
            </v-row>
          </v-form>

        </v-col>
      </v-row>
    </v-card>
    <SimpleDialog
      v-model="showXeroAuthUrlDialog"
      :title="'Xero Auth URL'"
      :loading="isBusy"
      :okOnly="true"
      yesText="Ok"
    >
      {{xeroAuthUrl}}
    </SimpleDialog>
  </v-container>
</template>

<script>
/**
 * This page is to show the list of invoice that will be sent to Xero.
 * This was originally create for the
 */

import partnerDataMixin from '../../../mixins/partnerDataMixin';
import SimpleDialog from "../../../components/dialogs/templates/SimpleDialog.vue";
import ButtonWithTooltip from "../../../components/pieces/ButtonWithTooltip.vue";
export default {
  name: 'PartnerSummary',
  mixins: [partnerDataMixin],
  components: {
    ButtonWithTooltip,
    SimpleDialog,
  },

  data: () => ({
    busy:false,
    xeroAuthUrl:null,
    showXeroAuthUrlDialog:false,
    rules: {
      date: [
        (v) => !!v || 'Required',
        (postDate) =>
          /^\d{4}-\d{2}-\d{2}$/.test(postDate)
          &&
          postDate.split('-').length === 3
          ||
          'Invalid date. Needs to be in format YYYY-MM-DD',
      ],
      requiredOnly: [(v) => !!v || 'Required'],
    },
    form: {
      issued_after: '',
      issued_before: '',
    },
    isValid: false,
  }),

  methods: {
    async getXeroAuthUrl()
    {
      this.busy = true;
      let response = await this.Api.send('get', '/admin/xero/tronic-auth-url');

      if (response.success) {
        this.xeroAuthUrl = response.data['xero-auth-url'];
        this.showXeroAuthUrlDialog = true;
      }
      this.busy = false;
    },

    submitPreview( ){
      let query = {
        "issued_after":this.form.issued_after,
        "issued_before":this.form.issued_before
      }
      this.$router.push({ name: 'XeroFeaturesPreview', query: query });
    },
  },
  computed: {
    isBusy(){
      return this.loading || this.busy;
    },
  },
};
</script>
